import VueCompositionAPI, { createApp } from '@vue/composition-api';
import '@/styles/style.scss';
import '@/styles/fonts.scss';
import Vue from 'vue';

import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import router from './router';
import store from './store';
import { XHR } from './XHR';

import { CommonFunc } from './prototype/CommonFunc';
import { Formatter } from './prototype/Formatter';
import { Validator } from './prototype/Validator';
import { DataFunc } from './prototype/DataFunc';
import { PDFFunc } from './prototype/PDFFunc';

import App from './App.vue';
import PageHeaderSlot from './components/PageHeaderSlot.vue';
import TextInput from './components/FormField/TextInput.vue';
import SelectField from './components/FormField/SelectField.vue';
import UploadField from './components/FormField/UploadField.vue';

import AuthMixin from '@/mixins/AuthMixin';

Vue.use(VueCompositionAPI);
Vue.use(CommonFunc);
Vue.use(Formatter);
Vue.use(Validator);
Vue.use(DataFunc);
Vue.use(PDFFunc);
Vue.use(XHR);

Vue.mixin(AuthMixin);

Vue.config.productionTip = false;

const vm = new Vue();

Vue.filter('formatLabelStatus', function (value: string) {
  const data: any = vm.$dataFunc.labelStatus();

  return vm.$validator.DataValid(data[value]) ? data[value] : value;
});

Vue.filter('formatStaffRole', function (value: string) {
  const data: any = vm.$dataFunc.staffRole();

  return vm.$validator.DataValid(data[value]) ? data[value] : value;
});

Vue.filter('formatQuotationStatus', function (value: string) {
  const data: any = vm.$dataFunc.quotationStatus();

  return vm.$validator.DataValid(data[value]) ? data[value] : value;
});

Vue.filter('formatPaymentStatus', function (value: string) {
  const data: any = vm.$dataFunc.paymentStatus();

  return vm.$validator.DataValid(data[value]) ? data[value] : value;
});

const app = createApp({
  render: (h: any) => h(App),
  router,
  store,
  vuetify,
  i18n,
});

app.component('PageHeaderSlot', PageHeaderSlot);
app.component('TextInput', TextInput);
app.component('SelectField', SelectField);
app.component('UploadField', UploadField);

app.mount('#app');
