//@ts-nocheck 

import _Vue from 'vue';
import { jsPDF } from "jspdf"

let notoSansTcRegularNormalFont = ""
let notoSansTcMediumBoldFont = ""
let josefinSansMediumBoldFont = ""
import("@/assets/fonts/pdf-fonts/NotoSansTC-Regular-normal.js").then((font) => {
    notoSansTcRegularNormalFont = font.notoSansTcRegularNormalFont
});

import("@/assets/fonts/pdf-fonts/NotoSansTC-Medium-bold.js").then((font) => {
    notoSansTcMediumBoldFont = font.notoSansTcMediumBoldFont
});

import("@/assets/fonts/pdf-fonts/JosefinSans-Medium-bold.js").then((font) => {
    josefinSansMediumBoldFont = font.josefinSansMediumBoldFont
});

export function PDFFunc(Vue: typeof _Vue): void {
    Vue.prototype.$jsPDF = {
        getPDFObj() {
            const pdf = new jsPDF();
            pdf.addFileToVFS('NotoSansTC-Regular-normal.ttf', notoSansTcRegularNormalFont);
            pdf.addFont('NotoSansTC-Regular-normal.ttf', 'NotoSansTC-Regular', 'normal');

            pdf.addFileToVFS('NotoSansTC-Medium-bold.ttf', notoSansTcMediumBoldFont);
            pdf.addFont('NotoSansTC-Medium-bold.ttf', 'NotoSansTC-Medium', 'bold');

            pdf.addFileToVFS('JosefinSans-Medium-bold.ttf', notoSansTcMediumBoldFont);
            pdf.addFont('JosefinSans-Medium-bold.ttf', 'JosefinSans-Medium', 'bold');
            return pdf;
        }
    }
}

declare module 'vue/types/vue' {
    interface Vue {
        $jsPDF: {
            getPDFObj(): any
        }
    }
}
